export default {
  type: "weekly-reports",
  starts_at: null,
  ends_at: null,
  report_uploaded_file: null,
  // counter_sales : null,
  // billing : null,
  // distributor_sales : null,
  // counter_purchase : null,
  // stationery : null,
  // cleaning_and_disposable_products : null,
  // others : null,
  // maintenance : null,
  // distributor : null,
  // wages : null,
  // rent : null,
  relationshipNames: [
    "organization",
    "establishment",
    "serviceCenter",
    "cafeteria",
  ],
  supplier: {
    type: "suppliers",
    id: null,
  },
  organization: {
    type: "organizations",
    id: null,
  },
  establishment: {
    type: "establishments",
    id: null,
  },
  serviceCenter: {
    type: "service-centers",
    id: null,
  },
  cafeteria: {
    type: "cafeterias",
    id: null,
  },
  allowedUsers: [],
};
