<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-6">
        <h1>
          {{ $timeZoneDateFormat(weeklyReport.starts_at) }} -
          {{ $timeZoneDateFormat(weeklyReport.ends_at) }} -
          {{ $t("WEEKLY_REPORTS.SALES") }}
        </h1>
      </span>
      <div class="col-6 text-right"></div>
    </div>

    <div class="row mb-5">
      <div class="col-12"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "weekly-report-view-sales",
  components: {},

  props: ["weeklyReport"],

  data() {
    return {};
  },

  created() {},

  methods: {},

  mounted() {},

  watch: {
    weeklyReport(weeklyReport) {},
  },
};
</script>
