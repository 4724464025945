var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row mb-5"},[_c('span',{staticClass:"display-4 col-6"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.weeklyReport.starts_at))+" - "+_vm._s(_vm.$timeZoneDateFormat(_vm.weeklyReport.ends_at))+" ")])]),_c('div',{staticClass:"col-6 text-right"})]),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-12"},[_c('dl',{staticClass:"row"},[_c('dt',{staticClass:"col-sm-4"},[_vm._v(_vm._s(_vm.$t("WEEKLY_REPORTS.STARTS_AT")))]),_c('dd',{staticClass:"col-sm-8"},[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.weeklyReport.starts_at))+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',{staticClass:"col-sm-4"},[_vm._v(_vm._s(_vm.$t("WEEKLY_REPORTS.ENDS_AT")))]),_c('dd',{staticClass:"col-sm-8"},[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.weeklyReport.ends_at))+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',{staticClass:"col-sm-4"},[_vm._v(_vm._s(_vm.$t("COMMON.REPORT_FILE")))]),_c('dd',{staticClass:"col-sm-8"},[(_vm.weeklyReport.report_uploaded_file)?_c('a',{attrs:{"href":_vm.weeklyReport.report_uploaded_file,"download":_vm.getFileName()}},[_vm._v(" "+_vm._s(_vm.getFileName() || _vm.weeklyReport.report_uploaded_file)+" ")]):_c('span',[_vm._v("-")])])]),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('dl',{staticClass:"row"},[_c('dt',{staticClass:"col-sm-4"},[_vm._v(_vm._s(_vm.$t("COMMON.ORGANIZATION")))]),_c('dd',{staticClass:"col-sm-8"},[_c('organization',{attrs:{"organization":_vm.weeklyReport.organization}})],1)]):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ESTABLISHMENTS) &&
          _vm.weeklyReport.establishment
        )?_c('dl',{staticClass:"row"},[_c('dt',{staticClass:"col-sm-4"},[_vm._v(_vm._s(_vm.$t("COMMON.ESTABLISHMENT")))]),_c('dd',{staticClass:"col-sm-8"},[_c('establishment',{attrs:{"establishment":_vm.weeklyReport.establishment}})],1)]):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_SERVICECENTERS) &&
          _vm.weeklyReport.serviceCenter
        )?_c('dl',{staticClass:"row"},[_c('dt',{staticClass:"col-sm-4"},[_vm._v(_vm._s(_vm.$t("COMMON.SERVICECENTER")))]),_c('dd',{staticClass:"col-sm-8"},[_c('serviceCenter',{attrs:{"serviceCenter":_vm.weeklyReport.serviceCenter}})],1)]):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_CAFETERIAS) &&
          _vm.weeklyReport.cafeteria
        )?_c('dl',{staticClass:"row"},[_c('dt',{staticClass:"col-sm-4"},[_vm._v(_vm._s(_vm.$t("COMMON.CAFETERIA")))]),_c('dd',{staticClass:"col-sm-8"},[_c('cafeteria',{attrs:{"cafeteria":_vm.weeklyReport.cafeteria}})],1)]):_vm._e(),_c('dl',{staticClass:"row"},[_c('dt',{staticClass:"col-sm-4"},[_vm._v(_vm._s(_vm.$t("COMMON.CREATED_AT")))]),_c('dd',{staticClass:"col-sm-8"},[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.weeklyReport.created_at))+" ")])]),_c('dl',{staticClass:"row"},[_c('dt',{staticClass:"col-sm-4"},[_vm._v(_vm._s(_vm.$t("COMMON.UPDATED_AT")))]),_c('dd',{staticClass:"col-sm-8"},[_vm._v(" "+_vm._s(_vm.$timeZoneDateFormat(_vm.weeklyReport.updated_at))+" ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }