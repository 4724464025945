<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <span class="display-4 col-6">
        <h1>
          {{ $timeZoneDateFormat(weeklyReport.starts_at) }} -
          {{ $timeZoneDateFormat(weeklyReport.ends_at) }}
        </h1>
      </span>
      <div class="col-6 text-right"></div>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("WEEKLY_REPORTS.STARTS_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(weeklyReport.starts_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("WEEKLY_REPORTS.ENDS_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(weeklyReport.ends_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.REPORT_FILE") }}</dt>
          <dd class="col-sm-8">
            <a
              v-if="weeklyReport.report_uploaded_file"
              :href="weeklyReport.report_uploaded_file"
              :download="getFileName()"
            >
              {{ getFileName() || weeklyReport.report_uploaded_file }}
            </a>
            <span v-else>-</span>
          </dd>
        </dl>
        <!-- <dl class="row">
          <dt class="col-sm-4">{{ $t("WEEKLY_REPORTS.COUNTER_SALES") }}</dt>
          <dd class="col-sm-8">
            {{ weeklyReport.counter_sales }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("WEEKLY_REPORTS.BILLING") }}</dt>
          <dd class="col-sm-8">
            {{ weeklyReport.billing }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("WEEKLY_REPORTS.DISTRIBUTOR_SALES") }}</dt>
          <dd class="col-sm-8">
            {{ weeklyReport.distributor_sales }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("WEEKLY_REPORTS.COUNTER_PURCHASE") }}</dt>
          <dd class="col-sm-8">
            {{ weeklyReport.counter_purchase }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("WEEKLY_REPORTS.STATIONERY") }}</dt>
          <dd class="col-sm-8">
            {{ weeklyReport.stationery }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("WEEKLY_REPORTS.CLEANING_AND_DISPOSABLE_PRODUCTS") }}
          </dt>
          <dd class="col-sm-8">
            {{ weeklyReport.cleaning_and_disposable_products }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("WEEKLY_REPORTS.OTHERS") }}</dt>
          <dd class="col-sm-8">
            {{ weeklyReport.others }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("WEEKLY_REPORTS.MAINTENANCE") }}</dt>
          <dd class="col-sm-8">
            {{ weeklyReport.maintenance }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("WEEKLY_REPORTS.DISTRIBUTOR") }}</dt>
          <dd class="col-sm-8">
            {{ weeklyReport.distributor }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("WEEKLY_REPORTS.WAGES") }}</dt>
          <dd class="col-sm-8">
            {{ weeklyReport.wages }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("WEEKLY_REPORTS.RENT") }}</dt>
          <dd class="col-sm-8">
            {{ weeklyReport.rent }}
          </dd>
        </dl> -->

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="weeklyReport.organization" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_ESTABLISHMENTS) &&
            weeklyReport.establishment
          "
        >
          <dt class="col-sm-4">{{ $t("COMMON.ESTABLISHMENT") }}</dt>
          <dd class="col-sm-8">
            <establishment :establishment="weeklyReport.establishment" />
          </dd>
        </dl>
        <dl
          class="row"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_SERVICECENTERS) &&
            weeklyReport.serviceCenter
          "
        >
          <dt class="col-sm-4">{{ $t("COMMON.SERVICECENTER") }}</dt>
          <dd class="col-sm-8">
            <serviceCenter :serviceCenter="weeklyReport.serviceCenter" />
          </dd>
        </dl>
        <dl
          class="row"
          v-if="
            $currentUserCan($permissions.PERM_VIEW_ANY_CAFETERIAS) &&
            weeklyReport.cafeteria
          "
        >
          <dt class="col-sm-4">{{ $t("COMMON.CAFETERIA") }}</dt>
          <dd class="col-sm-8">
            <cafeteria :cafeteria="weeklyReport.cafeteria" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(weeklyReport.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(weeklyReport.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
// import IconCheck from "@/components/IconCheck.vue";
import Cafeteria from "@/components/Cafeteria.vue";
import Establishment from "@/components/Establishment.vue";
import ServiceCenter from "@/components/ServiceCenter.vue";
import axios from "axios";

export default {
  name: "weekly-report-view-global",
  components: {
    // IconCheck,
    Cafeteria,
    ServiceCenter,
    Establishment,
  },

  props: ["weeklyReport"],

  data() {
    return {};
  },

  created() {},

  methods: {
    weeklyReportUpdated() {
      this.$emit("weeklyReportUpdated", true);
    },

    getFileName() {
      if (!this.weeklyReport || !this.weeklyReport.report_uploaded_file) {
        return null;
      }

      const startDate = this.$timeZoneDateFormat(
        this.weeklyReport.starts_at,
        "YYYY-MM-DD"
      );
      const endDate = this.$timeZoneDateFormat(
        this.weeklyReport.ends_at,
        "YYYY-MM-DD"
      );

      let fileName = `rapport_${startDate}_to_${endDate}`;

      if (
        this.weeklyReport.establishment &&
        this.weeklyReport.establishment.name
      ) {
        fileName += `_${this.weeklyReport.establishment.company_name}`;
      }

      if (this.weeklyReport.cafeteria && this.weeklyReport.cafeteria.name) {
        fileName += `_${this.weeklyReport.cafeteria.name}`;
      }

      // Get the original file extension if possible
      const originalFile = this.weeklyReport.report_uploaded_file;
      const fileExtension = originalFile.split(".").pop();

      return fileName + (fileExtension ? `.${fileExtension}` : "");
    },

    downloadReport() {
      if (!this.weeklyReport || !this.weeklyReport.report_uploaded_file) {
        return;
      }

      // Show loading notification
      this.$notify({
        type: "info",
        title: this.$t("COMMON.INFO"),
        text: this.$t("COMMON.DOWNLOADING"),
      });

      // Use axios to download with proper headers
      const options = {
        headers: {},
        responseType: "blob", // Important for file downloads
      };

      axios
        .get(this.weeklyReport.report_uploaded_file, options)
        .then((response) => {
          // Create a temporary anchor element
          const link = document.createElement("a");

          // Create a Blob URL from the response data
          const blob = new Blob([response.data], {
            type:
              response.headers["content-type"] || "application/octet-stream",
          });
          const blobUrl = window.URL.createObjectURL(blob);

          // Set the download link properties
          link.href = blobUrl;
          link.download = this.getFileName();

          // Append to the document, click, and remove
          document.body.appendChild(link);
          link.click();

          // Clean up
          setTimeout(() => {
            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(link);
          }, 100);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
          this.$notify({
            type: "error",
            title: this.$t("COMMON.ERROR"),
            text: this.$t("COMMON.DOWNLOAD_FAILED"),
          });
        });
    },
  },

  mounted() {},

  watch: {
    weeklyReport(weeklyReport) {},
  },
};
</script>
